// Initialize Cloud Firestore through Firebase
import { getFirestore } from "firebase/firestore"
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCTL5q4TgjFSvr4rTAPIS6kFZTYHvVguwI",
  authDomain: "hvaqcoolingsolutions.firebaseapp.com",
  projectId: "hvaqcoolingsolutions",
  storageBucket: "hvaqcoolingsolutions.appspot.com",
  messagingSenderId: "617450376379",
  appId: "1:617450376379:web:9bffceb0361d335d39ad9e",
  measurementId: "G-QWY0ZW87C0"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const db = getFirestore();