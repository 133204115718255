import React, { useState, useContext } from 'react'
import Footer from '../../CoreComponents/Footer'
import Header from '../../CoreComponents/Header'
import './Assets/css/Home.css'

// import functions and apis from API_And_Functions
import { api_get_data, api_post_data } from '../../API_And_FUNCTIONS/API'
// import {apiCall} from '../../API_And_FUNCTIONS/Functions'

import { UserContext } from '../../UserContext'
import RevolutionSlider from '../../CoreComponents/RevolutionSlider'

function Home() {

  const { data, name } = useContext(UserContext)

  const [msg, setMsg] = useState()
  const [title, setTitle] = useState()
  const [body, setBody] = useState()


  const apiCall = async () => {
    // const dat = await api([{ urlParams: '/todos/1', image: [{ id: 1, img: 1 }, { id: 2, img: 2 }, { id: 3, img: 3 },] }])
    const dat = await api_get_data('/todos/1')
    data[1](dat)
  }

  const apiPostCall = async () => {
    var formData = new FormData();
    formData.append('title', title);
    formData.append('body', body);
    formData.append('userId', 1);
    const postResponse = await api_post_data('/posts', formData)
    console.log(postResponse)
  }

  return (
    <>



      <div id="wrapper">
        {/* header begin */}
        <Header />
        {/* header close */}
        {/* content begin */}
        <div id="content" className="no-bottom no-top">
          {/* revolution slider begin */}
          <RevolutionSlider />
          {/* revolution slider close */}
          {/* section begin */}


          <section id="section-about">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3 text-center wow fadeInUp">
                  <h1>About Us </h1>
                  <div className="separator"><span><i className="fa fa-circle" /></span></div>
                  <div className="spacer-single" />
                </div>

                <section id="section-intro" style={{ backgroundSize: 'cover' }}>
                  <div className="container" style={{ backgroundSize: 'cover' }}>
                    <div className="row align-items-center" style={{ backgroundSize: 'cover' }}>
                      <div className="col-lg-6" style={{ backgroundSize: 'cover' }}>
                        <img src="images/about/about.png" alt className="img-responsive wow fadeInUp animated" data-wow-duration="1.5s" style={{ visibility: 'visible', animationDuration: '1.5s', animationName: 'fadeInUp' }} />
                      </div>
                      <div className="col-lg-6" style={{ backgroundSize: 'cover' }}>
                        <div className="padding20" style={{ backgroundSize: 'cover' }}>
                          <h2>We are team based on Kerala. Our expertise on HVAC Solutions.</h2>
                          <p>
                            We are is a Kerala based specialist provider for Heating, Ventilation and Air Conditioning solution to commercial, industrial and residential spaces.We provide high quality engineering and service support as well as the design and consulting to meet individual client requirements.
                            We undertakes the design, supply, installation, commissioning and maintenance of multi storey commercial, industrial, residential buildings.., Are you looking for HVAC in Kerala, then you are at the right place.
                          </p>
                          <a href="#" className="btn-custom font-weight-bold text-white">Contact Sales</a>
                        </div>
                      </div>
                      <div className="clearfix" style={{ backgroundSize: 'cover' }} />
                    </div>
                  </div>
                </section>

                <div className="col-md-4 wow fadeInLeft">
                  <h3><span className="id-color">Our</span> Vision</h3>
                  We strive to become one of the leading and most reliable HVAC contracting companies in INDIA, continuously con- forming to our ever-growing professional and ethical demeanor as well as quality assurance standards to earn the privilege of being your Contractor of choice.
                  <div className="spacer-single" />
                  <a className="image-popup-no-margins" href="images/portfolio/cols-4/pf%20(6).jpg">
                    <img src="images/portfolio/cols-4/pf%20(6).jpg" className="img-responsive" alt />
                  </a>
                </div>
                <div className="col-md-4 wow fadeInUp" data-wow-delay=".2s">
                  <h3><span className="id-color">Quality</span> work</h3>
                  Guided by our vision we shall deliver High-quality, cost-effective works on Schedule and enhance our client’s satisfaction through continuous improvement of our business practices in terms of safety, quality, services, and delivery as well as environmental Protection. We shall be your HVAC Contractor of choice.
                  <div className="spacer-single" />
                  <a className="image-popup-no-margins" href="images/portfolio/cols-4/pf%20(7).jpg">
                    <img src="images/portfolio/cols-4/pf%20(7).jpg" className="img-responsive" alt />
                  </a>
                </div>
                <div className="col-md-4 wow fadeInRight">
                  <h3><span className="id-color">Quick</span> response</h3>
                  All air-conditioning and refrigeration systems, like any other machine, depreciate with time. And if not properly maintained, they progressively lose efficiency, consume more power, and drain your profits. So we are eagerly waiting for your call to discuss your air-conditioning and refrigeration system needs.
                  <div className="spacer-single" />
                  <a className="image-popup-no-margins" href="images/portfolio/cols-4/pf%20(8).jpg">
                    <img src="images/portfolio/cols-4/pf%20(8).jpg" className="img-responsive" alt />
                  </a>
                </div>
              </div>
            </div>
          </section>


          {/* section close */}





          {/* section begin */}
          <section id="section-steps" className="xtext-light">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3 text-center wow fadeInUp">
                  <h1 style={{ color: 'white' }}>Our Process</h1>
                  <div className="separator"><span><i className="fa fa-circle" /></span></div>
                  <div className="spacer-single" />
                </div>
                <div className="col-md-12">
                  <div className="de_tab tab_steps">
                    <ul className="de_nav">
                      <li className="xactive wow fadeIn" data-wow-delay="0s"><span>Meet &amp; Agree</span>
                        <div className="v-border" />
                      </li>
                      <li className="wow fadeIn" data-wow-delay=".2s"><span>Idea &amp; Concept</span>
                        <div className="v-border" />
                      </li>
                      <li className="wow fadeIn" data-wow-delay=".4s"><span>Design &amp; Create</span>
                        <div className="v-border" />
                      </li>
                      <li className="wow fadeIn" data-wow-delay=".6s"><span>Build &amp; Install</span>
                        <div className="v-border" />
                      </li>
                      <li className="wow fadeIn" data-wow-delay=".8s"><span>Services &amp; AMC</span>
                        <div className="v-border" />
                      </li>
                    </ul>
                    <div className="de_tab_content">
                      <div id="tab1" style={{ color: "white" }}>
                        We are well conditioned in providing better customer services like home delivery and
                        as such. For better choice and consumer satisfaction products are arranged at different
                        floor to enhance best customer satisfaction and selection. We have efficient tie up with
                        authorised service network of Daikin, Carrier, Toshiba, Mitsubishi, General, Panasonic
                        and other leading brands
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* section close */}

          <section id="section-download" className="xno-top xno-bottom" style={{ backgroundColor: "white", backgroundSize: 'cover' }}>
            <div className="container" style={{ backgroundSize: 'cover' }}>
              <div className="row" style={{ backgroundSize: 'cover' }}>
                <div className="col-md-6 offset-md-3 text-center wow xfadeInUp xanimated" style={{ visibility: 'visible', animationName: 'fadeInUp', backgroundSize: 'cover' }}>
                  <h1>Our Projects and Services</h1>
                  <div className="separator" style={{ backgroundSize: 'cover' }}><span><i className="fa fa-circle" /></span></div>
                  <div className="spacer-single" style={{ backgroundSize: 'cover' }} />
                </div>
                <div className="col-md-6 text-center wow zoomIn xanimated" style={{ visibility: 'visible', animationName: 'zoomIn', backgroundSize: 'cover' }}>
                  {/* <i className="fa fa-apple large xid-color" /> */}
                  <img src={"images/background/services.png"} height="100" />
                  <div className="spacer-single" style={{ backgroundSize: 'cover' }} />
                  <a href="/projects" className="btn-line">Projects</a>
                </div>
                <div className="col-md-6 text-center wow zoomIn xanimated" style={{ visibility: 'visible', animationName: 'zoomIn', backgroundSize: 'cover' }}>
                  {/* <i className="fa fa-android large xid-color" /> */}
                  <img src={"images/background/projects.jpg"} height="100" />
                  <div className="spacer-single" style={{ backgroundSize: 'cover' }} />
                  <a href="/services" className="btn-line">Services</a>
                </div>
              </div>
              <div className="spacer-double" style={{ backgroundSize: 'cover' }} />
            </div>
          </section>


          {/* section begin */}
          {/* <section id="view-all-projects" class="call-to-action bg-color text-center" data-speed="5" data-type="background" aria-label="view-all-projects">
          <a href="project-wide-4-cols.html" class="btn btn-line black btn-big">View All Projects</a>
      </section> */}
          {/* logo carousel section close */}
          {/* section begin */}
          <section id="section-testimonial" className="xtext-light">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3 text-center wow fadeInUp">
                  <h1>Customer Says</h1>
                  <div className="separator"><span><i className="fa fa-circle" /></span></div>
                  <div className="spacer-single" />
                </div>
              </div>
              <div id="testimonial-carousel" className="owl-carousel owl-theme de_carousel wow fadeInUp" data-wow-delay=".3s">
                <div className="item">
                  <div className="de_testi">
                    <blockquote>
                      <p>
                        Its been a right choice to choose HVAQ Team for our project. They are very professional and responsive. They are very good at what they do.
                      </p>
                      <div className="de_testi_by">
                        SAALI, BELLEHUT
                      </div>
                    </blockquote>
                  </div>
                </div>
                <div className="item">
                  <div className="de_testi">
                    <blockquote>
                      <p>
                        One of our best projects. We are very happy with the work they have done. They are very professional and they where maintaining the work on time.
                      </p>
                      <div className="de_testi_by">
                        HOME KEY, ARIF
                      </div>
                    </blockquote>
                  </div>
                </div>
                <div className="item">
                  <div className="de_testi">
                    <blockquote>
                      <p>
                        Value for money, quality and timely delivery. Highly recommended for any one who where looking for HVAQ Team.
                      </p>
                      <div className="de_testi_by">
                        KM SALEEM ASSOCIATES, SALEEM
                      </div>
                    </blockquote>
                  </div>
                </div>
                <div className="item">
                  <div className="de_testi">
                    <blockquote>
                      <p>
                        Action speaks louder than words, and we are very happy with their action which they have done.
                      </p>
                      <div className="de_testi_by">
                        Sathva architect, NOUSHAD
                      </div>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* section close */}
          <section id="de-map" aria-label="map-container">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="map-container map-fullwidth img-rounded">
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.7152203584424!2d-118.2453181849353!3d34.05117548060617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c648d9808fbd%3A0xb79dfbc6ae338c12!2s100%20S%20Main%20St%2C%20Los%20Angeles%2C%20CA%2090012%2C%20USA!5e0!3m2!1sen!2sid!4v1592143290578!5m2!1sen!2sid" width="600" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3912.514710637815!2d75.81068291480541!3d11.297040191969595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x112459aab4e3a554!2zMTHCsDE3JzQ5LjMiTiA3NcKwNDgnNDYuMyJF!5e0!3m2!1sen!2sin!4v1644946595647!5m2!1sen!2sin" width={600} height={300} style={{ border: 0 }} allowFullScreen loading="lazy" />
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>

      <Footer />
    </>

  )
}

export default Home
