const protocol = 'https://'
//const protocol = 'http://'

const domain = `jsonplaceholder.typicode.com`
// const domain = `reqres.in`

const url = `${protocol}${domain}`

export default url


