import React from 'react'
import '../Assets/css/Footer.css'

const year = new Date().getFullYear();

function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="row" style={{ color: 'black' }}>
                    <div className="col-md-4">
                        <img style={{ height: 66 }} src="images/hvaq-logo.png" className="logo-small" alt /><br />
                        HVAQ is the leading business group based in Malappuram which was established in
                        2018. The Group has won the hearts of many by
                        performing in line with the needs of common people of Malappuram. Vast year of
                        experience opened the path for the establishment of HVAQ Sales Corporation in 2020.
                    </div>
                    <div className="col-md-4">
                        {/* Hide if not in home */}
                        {console.log("Window.location ", window.location.pathname)}
                        {window.location.pathname == "/" && <div className="widget widget_recent_post">
                            <h3>Quick Referance</h3>
                            <ul>
                                <li><a href="#section-slider">Home</a></li>
                                <li><a href="#section-about">About us</a></li>
                                <li><a href="#section-steps">Our process</a></li>
                                <li><a href="#section-portfolio">All projects</a></li>
                                <li><a href="#section-testimonial">Customer says</a></li>
                            </ul>
                        </div>}

                    </div>
                    <div className="col-md-4">
                        <h3>Contact Us</h3>
                        <div className="widget widget-address">
                            <address>
                                <span>H VAQ COOLING SOLUTION MALAPPURAM, OPPO AM MOTORS </span>
                                <span><strong>Phone 1:</strong>(+91) 999 555 1362</span>
                                <span><strong>Phone 2:</strong>(+91) 965 564  3817</span>
                                <span><strong>Email:</strong><a href="mailto:hvaqcoolingsolution@gmail.com">hvaqcoolingsolution@gmail.com</a></span>
                            </address></div>
                    </div>
                    {/* <div class="col-md-3">
            <div class="widget widget_recent_post">
                <h3>Our Services</h3>
                <ul>
                    <li><a href="#">Split AC</a></li>
                    <li><a href="#">Ductable AC</a></li>
                    <li><a href="#">VRF</a></li>
                    <li><a href="#">AHU</a></li>
                    <li><a href="#">Operation Theater</a></li>
                    <li><a href="#">AMC</a></li>
                </ul>
            </div>
        </div> */}
                </div>
            </div>
            <div className="subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 d-flex justify-content-center">
                            © Copyright {year} - Hvaq. All rights reserved.<span id="footer-content">
                                {/* Archi Interior Design Template by <span class="id-color">Designesia</span> */}
                            </span></div>
                        <div className="col-md-4 d-flex justify-content-center">
                            <span id="footer-content ">
                                <a href="https://wa.me/917907960873" target={"_blank"}> Developed by Jasir </a>
                            </span></div>
                        <div className="col-md-4 d-flex justify-content-center">
                            <div className="social-icons">
                                <a href="#"><i className="fa fa-facebook fa-lg" /></a>
                                <a href="#"><i className="fa fa-facebook fa-lg" /></a>
                                <a href="#"><i className="fa fa-facebook fa-lg" /></a>
                                <a href="#"><i className="fa fa-google-plus fa-lg" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="#" id="back-to-top" />
        </footer>
    )
}

export default Footer
