
import React from 'react'
import Footer from '../../CoreComponents/Footer'
import Header from '../../CoreComponents/Header'

function Services() {
    return (
        <>
            {/* <Header /> */}

            <div id="wrapper">
                {/* header begin */}
                <Header />
                {/* header close */}
                {/* subheader */}
                <section id="subheader" data-speed={8} data-type="background">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Services</h1>
                                <ul className="crumb">
                                    {/* <li><a href="index.html">Home</a></li> */}
                                    <li className="sep">/</li>
                                    <li>Services</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* subheader close */}
                {/* content begin */}
                <div id="content" className="no-top no-bottom">
                    {/* section service begin */}
                    <section id="section-service-1" className="side-bg no-padding">
                        <div className="image-container col-lg-6 pull-left jamaldesignimage" data-delay={0}>
                            <div className="background-image " />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="inner-padding ">
                                    <div className="col-lg-6 offset-lg-6 col-md-12 wow fadeInRight jamaldesign" data-wow-delay=".5s">
                                        <h3 className="id-color">DUCTABLE AC</h3>
                                        {/* Ensures flexible use of space superior air distribution for comfortable living. */}
                                        A Ductable air conditioner cools the entire area of the property as it comes with separate air-conditioned zones. ... The ductable type AC provides cooling across the whole property and it also is functioned to cool a specific part or room of the house.
                                        <div className="spacer-single" />
                                        {/* <a href="service-1.html" class="btn-line">Read More</a> */}
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section service close */}
                    {/* section service begin */}
                    <section id="section-service-2" className="side-bg no-padding">
                        <div className="image-container col-lg-5 offset-lg-7 col-md-12 pull-right jamaldesignimage" data-delay={0}>
                            <div className="background-image" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="inner-padding">
                                    <div className="col-lg-6 col-md-12 wow fadeInLeft jamaldesign" data-wow-delay=".5s">
                                        <h3 className="id-color">AHU</h3>
                                        {/* AHU system particularly in large scale buildings for roviding both Heating Cooling for
                          multiple zones. */}
                                        An Air Handling Unit (AHU) is used to re-condition and circulate air as part of a heating, ventilating and air-conditioning system. The basic function of the AHU is to take in outside air, re-condition it and supply it as fresh air to a building.
                                        <div className="spacer-single" />
                                        {/* <a href="service-1.html" class="btn-line">Read More</a> */}
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section service close */}
                    {/* section service begin */}
                    <section id="section-service-3" className="side-bg no-padding">
                        <div className="image-container col-lg-5 pull-left" data-delay={0}>
                            <div className="background-image" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="inner-padding">
                                    <div className="col-lg-6 offset-lg-6 col-md-12 wow fadeInRight jamaldesign" data-wow-delay=".5s">
                                        <h3 className="id-color">SPLIT AC</h3>
                                        {/* Split AC means the air conditioning system is made up of two separate units. The air-cooled condensing unit called the compressor unit is placed outside the air-conditioned area whereas the air-handling unit is inside the room where the AC is fixed. */}
                                        Enjoy the innovation for perfective with
                                        advanced features that gives you the best
                                        in class Air Conditioning Experience.
                                        Available from 0.8 TR to 3TR units, air
                                        throw up to 25 meters in heavy duty
                                        models.
                                        <div className="spacer-single" />
                                        {/* <a href="service-1.html" class="btn-line">Read More</a> */}
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section service close */}
                    {/* section service begin */}
                    <section id="section-service-4" className="side-bg no-padding">
                        <div className="image-container col-lg-5 offset-lg-7 col-md-12 pull-right" data-delay={0}>
                            <div className="background-image" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="inner-padding">
                                    <div className="col-lg-6 col-md-12 wow fadeInLeft jamaldesign" data-wow-delay=".5s">
                                        <h3 className="id-color">VRF</h3>
                                        The VRV/VRF is the most advanced aircondition system in the world and is ideal
                                        for large and small spaces. VRT - Variable
                                        refrigerant Temperature in Indoor unit &amp;
                                        Outdoor unit
                                        {/* Variable Refrigerant Flow (VRF) HVAC systems consist of outdoor units connected to multiple indoor units via refrigerant piping to provide cooling and heating to individual zones. */}
                                        <div className="spacer-single" />
                                        {/* <a href="service-1.html" class="btn-line">Read More</a> */}
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section service close */}
                    {/* section service begin */}
                    <section id="section-service-7" className="side-bg no-padding">
                        <div className="image-container col-lg-5 pull-left" data-delay={0}>
                            <div className="background-image" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="inner-padding">
                                    <div className="col-lg-6 offset-lg-6 col-md-12 wow fadeInRight jamaldesign" data-wow-delay=".5s">
                                        <h3 className="id-color">AMC</h3>
                                        An annual maintenance contract (AMC) is an agreement with a service provider for repair
                                        and maintenance of property used by your company. The service can be of any property
                                        owned by your company from the large manufacturing machines creating your products down
                                        to the computers and printers used in your offices.
                                        <div className="spacer-single" />
                                        {/* <a href="service-1.html" class="btn-line">Read More</a> */}
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section service close */}


                    {/* section service begin */}
                    <section id="section-service-6" className="side-bg no-padding">
                        <div className="image-container col-lg-5 offset-lg-7 col-md-12 pull-right" data-delay={0}>
                            <div className="background-image" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="inner-padding">
                                    <div className="col-lg-6 col-md-12 wow fadeInLeft jamaldesign" data-wow-delay=".5s">
                                        <h3 className="id-color">OPERATION THEATER</h3>
                                        Air handling in the OT including air Quality: Air is supplied through Terminal HEPA
                                        (High-Efficiency Particulate Air) filters in the ceiling. ... HEPA filters of efficiency
                                        99.97% down to 0.3 microns or higher efficiency are to be provided.
                                        <div className="spacer-single" />
                                        {/* <a href="service-1.html" class="btn-line">Read More</a> */}
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section service close */}
                    {/* section service begin */}
                    <section id="section-service-8" className="side-bg no-padding">
                        <div className="image-container col-lg-5 pull-left" data-delay={0}>
                            <div className="background-image" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="inner-padding">
                                    <div className="col-lg-6 offset-lg-6 col-md-12 wow fadeInRight jamaldesign" data-wow-delay=".5s">
                                        <h3 className="id-color">HIDE AWAY</h3>

                                        Flexible use of space is made possible
                                        using ducts to create a room filled
                                        with comfort. Enhance the decor of
                                        your room with the new unobtrusive
                                        concealed series
                                        <div className="spacer-single" />
                                        {/* <a href="service-1.html" class="btn-line">Read More</a> */}
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section service close */}
                    {/* section service begin */}
                    <section id="section-service-9" className="side-bg no-padding">
                        <div className="image-container col-lg-5 offset-lg-7 col-md-12 pull-right" data-delay={0}>
                            <div className="background-image" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="inner-padding">
                                    <div className="col-lg-6 col-md-12 wow fadeInLeft jamaldesign" data-wow-delay=".5s">
                                        <h3 className="id-color">DUCT FABRICATION </h3>


                                        The fabrication process for HVAC ducts involves precisely cutting and bending sheet metal into the desired cylindrical or rectangular shapes. A professional is needed to make sure all of the manufactured pieces fit together tightly.
                                        <div className="spacer-single" />
                                        {/* <a href="service-1.html" class="btn-line">Read More</a> */}
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section service close */}
                    {/* section service begin */}
                    <section id="section-service-10" className="side-bg no-padding">
                        <div className="image-container col-lg-5 pull-left" data-delay={0}>
                            <div className="background-image" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="inner-padding">
                                    <div className="col-lg-6 offset-lg-6 col-md-12 wow fadeInRight jamaldesign" data-wow-delay=".5s">
                                        <h3 className="id-color">Tower AC</h3>
                                        A versatile air conditioning solution for large commercial spaces such as open-layout offices, showrooms and banks particularly when you need it in no time.
                                        <div className="spacer-single" />
                                        {/* <a href="service-1.html" class="btn-line">Read More</a> */}
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section service close */}
                </div>
                {/* footer begin */}
                <Footer />
                {/* footer close */}
            </div>


            {/* <Footer /> */}
        </>
    )
}

export default Services

