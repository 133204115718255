import React, { useState } from 'react'

function RevolutionSlider() {
    const [data, setData] = useState([
        {
            "id":1,
            "img":"images/slider/wide1.jpg",
            "light_heading":"Our Expertise For",
            "bold_heading":"HVAQ",
        },
        {
            "id":1,
            "img":"images/slider/wide2.jpg",
            "light_heading":"Breath",
            "bold_heading":"Freshness of air",
        },
        {
            "id":1,
            "img":"images/slider/wide3.jpg",
            "light_heading":"HD Filter with Anti-Virus Protection",
            "bold_heading":" Your Life Easier",
        },
       
    ])


  return (
    <section id="section-slider" className="fullwidthbanner-container" aria-label="section-slider">
      <div id="revolution-slider">
        <ul>
            {data.map((item, index) => <li data-transition="fade" data-slotamount={10} data-masterspeed={200} data-thumb>
            {/*  BACKGROUND IMAGE */}
            <img src={item.img} alt />
            <div className="tp-caption big-white sft" data-x={0} data-y={150} data-speed={800} data-start={400} data-easing="easeInOutExpo" data-endspeed={450}>
              {item.light_heading}
            </div>
            <div className="tp-caption ultra-big-white customin customout start" data-x={0} data-y="center" data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:2;scaleY:2;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;" data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.85;scaleY:0.85;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;" data-speed={800} data-start={400} data-easing="easeInOutExpo" data-endspeed={400}>
              {item.bold_heading}
            </div>
            {/* <div class="tp-caption sfb" data-x="0" data-y="335" data-speed="400" data-start="800" data-easing="easeInOutExpo"><a href="#" class="btn-slider">Our Portfolio</a></div> */}
          </li>
            )}
        </ul>
      </div>
    </section>
  )
}

export default RevolutionSlider