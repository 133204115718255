import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import '../Assets/css/Header.css'
import { UserContext } from '../UserContext'
import Cookie from 'js-cookie'

function Header() {
    const { token } = useContext(UserContext)
    const history = useHistory()
    return (
        // <div className="headerContainer">
        //     <Link to="/">Home</Link>
        //     <Link to="/orders">Orders</Link>
        //     <Link to="/products">Products</Link>
        //     <div style={{ cursor: 'pointer' }}
        //         onClick={() => {
        //             Cookie.remove('token');
        //             localStorage.removeItem('token')
        //             token[1](null);
        //             history.push('/login')
        //         }}
        //     >
        //         Logout
        //     </div>
        // </div >
        <header>
        <div className="info">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="column">Working Hours Monday - Friday <span className="id-color"><strong>08:00-16:00</strong></span></div>
                <div className="column">Toll Free <span className="id-color"><strong>1800.899.900</strong></span></div>
                {/* social icons */}
                <div className="column social">
                  <a href="#"><i className="fa fa-facebook" /></a>
                  <a href="#"><i className="fa fa-twitter" /></a>
                  <a href="#"><i className="fa fa-rss" /></a>
                  <a href="#"><i className="fa fa-google-plus" /></a>
                  <a href="#"><i className="fa fa-envelope-o" /></a>
                </div>
                {/* social icons close */}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="md-flex">
                {/* logo begin */}
                <div id="logo">
                  <a href="index.html">
                    <img style={{height: 66, marginLeft: '-90px'}} className="logo" src="images/hvaq-logo.png" alt />
                  </a>
                </div>
                {/* logo close */}
                {/* small button begin */}
                <span id="menu-btn" />
                {/* small button close */}
                {/* mainmenu begin */}
                <div className="md-flex-col">
                  <nav className="md-flex">
                    <ul id="mainmenu">
                      <li><a href="/">Home<span /></a></li>
                      <li><a href="projects">Projects<span /></a></li>
                      <li><a href="services">Services</a></li>
                    </ul>
                  </nav>
                  {/* mainmenu close */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
}

export default Header
