import React, { useEffect } from 'react'
import { initializeApp } from "firebase/app";
import { getFirestore, orderBy } from "firebase/firestore";
import Footer from '../../CoreComponents/Footer'
import Header from '../../CoreComponents/Header'
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from '../../CoreComponents/FirebaseConfig';

function Projects() {

    const [projects, setProjects] = React.useState([]);


    useEffect(async () => {


        const q = query(collection(db, "projects"),
            // where("capital", "==", true)
            orderBy("rank", "asc"),
        );

        const querySnapshot = await getDocs(q);

        let data = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            data.push(doc.data())

        });

        // add data to projects array
        setProjects(data)


        return () => {
            console.log("closed!")
        }
    }, [])


    return (
        <div id="wrapper" >
            {/* header begin */}
            <Header />
            {/* header close */}
            {/* subheader */}
            <section id="subheader" data-speed={8} data-type="background">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Projects</h1>
                            <ul className="crumb">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li className="sep">/</li>
                                <li>Projects</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section id="section-why-choose-us-2" style={{ backgroundSize: 'cover' }}>
                <div className="container" style={{ backgroundSize: 'cover' }}>
                    <div className="row" style={{ backgroundSize: 'cover' }}>
                        <div className="col-md-6 offset-md-3 text-light text-center wow fadeInUp animated" style={{ backgroundSize: 'cover', visibility: 'visible', animationName: 'fadeInUp' }}>
                            <h2>On going Projects</h2>
                            <div className="separator" style={{ backgroundSize: 'cover' }}><span><i className="fa fa-square" /></span></div>
                            <div className="spacer-single" style={{ backgroundSize: 'cover' }} />
                        </div>
                        <div className="clearfix" style={{ backgroundSize: 'cover' }} />


                        {projects.length > 0 && projects.filter(item => item.type == "ongoing").map((item, index) => {
                            return <>
                                <div className="col-lg-4 mb30 wow fadeInUp animated" data-wow-delay=".3s" style={{ backgroundSize: 'cover', visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUp' }}>
                                    <img src={item.imageurl} className="img-responsive" alt />
                                    <div className="text padding30" style={{ backgroundImage: 'linear-gradient(-8deg, #25e0c6, #069cda)', backgroundSize: 'cover', color: 'white' }}>
                                        <h3 style={{ color: "white" }}>{item.rank} : {item.heading}</h3>
                                        <p >{item.description}</p>
                                    </div>
                                </div>
                            </>
                        })}


                        {/* <div className="col-lg-4 mb30 wow fadeInUp animated" data-wow-delay=".3s" style={{ backgroundSize: 'cover', visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUp' }}>
                            <img src="images-kitchen/misc/r1.jpg" className="img-responsive" alt />
                            <div className="text padding30" style={{ backgroundImage: 'linear-gradient(-8deg, #25e0c6, #069cda)', backgroundSize: 'cover', color: 'white' }}>
                                <h3 style={{ color: "white" }}>U-Shaped Kitchen</h3>
                                <p >Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 mb30 wow fadeInUp animated" data-wow-delay=".3s" style={{ backgroundSize: 'cover', visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUp' }}>
                            <img src="images-kitchen/misc/r1.jpg" className="img-responsive" alt />
                            <div className="text padding30" style={{ backgroundImage: 'linear-gradient(-8deg, #25e0c6, #069cda)', backgroundSize: 'cover', color: 'white' }}>
                                <h3 style={{ color: "white" }}>U-Shaped Kitchen</h3>
                                <p >Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.</p>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>



            <section id="section-why-choose-us-2" style={{ backgroundSize: 'cover' }}>
                <div className="container" style={{ backgroundSize: 'cover' }}>
                    <div className="row" style={{ backgroundSize: 'cover' }}>
                        <div className="col-md-6 offset-md-3 text-light text-center wow fadeInUp animated" style={{ backgroundSize: 'cover', visibility: 'visible', animationName: 'fadeInUp' }}>
                            <h2>Completed Projects</h2>
                            <div className="separator" style={{ backgroundSize: 'cover' }}><span><i className="fa fa-square" /></span></div>
                            <div className="spacer-single" style={{ backgroundSize: 'cover' }} />
                        </div>
                        <div className="clearfix" style={{ backgroundSize: 'cover' }} />

                        {projects.length > 0 && projects.filter(item => item.type == "completed").map((item, index) => {
                            return <>
                                <div className="col-lg-4 mb30 wow fadeInUp animated" data-wow-delay=".3s" style={{ backgroundSize: 'cover', visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUp' }}>
                                    <img src={item.imageurl} className="img-responsive" alt />
                                    <div className="text padding30" style={{ backgroundImage: 'linear-gradient(-8deg, #25e0c6, #069cda)', backgroundSize: 'cover', color: 'white' }}>
                                        <h3 style={{ color: "white" }}>{item.rank} : {item.heading}</h3>
                                        <p >{item.description}</p>
                                    </div>
                                </div>
                            </>
                        })}


                    </div>
                </div>
            </section>

            <section id="section-why-choose-us-2" style={{ backgroundSize: 'cover' }}>
                <div className="container" style={{ backgroundSize: 'cover' }}>
                    <div className="row" style={{ backgroundSize: 'cover' }}>
                        <div className="col-md-6 offset-md-3 text-light text-center wow fadeInUp animated" style={{ backgroundSize: 'cover', visibility: 'visible', animationName: 'fadeInUp' }}>
                            <h2>AMC Projects and Services</h2>
                            <div className="separator" style={{ backgroundSize: 'cover' }}><span><i className="fa fa-square" /></span></div>
                            <div className="spacer-single" style={{ backgroundSize: 'cover' }} />
                        </div>
                        <div className="clearfix" style={{ backgroundSize: 'cover' }} />

                        {projects.length > 0 && projects.filter(item => item.type == "amc").map((item, index) => {
                            return <>
                                <div className="col-lg-4 mb30 wow fadeInUp animated" data-wow-delay=".3s" style={{ backgroundSize: 'cover', visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUp' }}>
                                    <img src={item.imageurl} className="img-responsive" alt />
                                    <div className="text padding30" style={{ backgroundImage: 'linear-gradient(-8deg, #25e0c6, #069cda)', backgroundSize: 'cover', color: 'white' }}>
                                        <h3 style={{ color: "white" }}>{item.rank} : {item.heading}</h3>
                                        <p >{item.description}</p>
                                    </div>
                                </div>
                            </>
                        })}


                    </div>
                </div>
            </section>

            {/* footer begin */}
            <Footer />
            {/* footer close */}
        </div>

    )
}

export default Projects